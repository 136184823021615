<template>
  <TTView>
    <VRow>
      <VCol>
        <AccountCard
          :account="account"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <UserRemoveForm
          :entity="user"
          @update:uuid="user.uuid = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_ACCOUNT, DEFAULT_USER } from '../../../components/accounts/accounts/common';
import AccountCard from '../../../components/accounts/accounts/AccountCard.vue';
import UserRemoveForm from '../../../components/accounts/accounts/UserRemoveForm.vue';

export default {
  name: 'UserRemove',

  components: {
    AccountCard,
    UserRemoveForm,
  },

  data() {
    return {
      loading: false,
      account: { ...DEFAULT_ACCOUNT },
      user: { ...DEFAULT_USER },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { accountId: id } = this.$route.params;
        const data = { id };
        const accountResponse = await this.$di.api.Account.getAccount(data);

        this.account = accountResponse.account;
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        // no-finally
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const { accountId: id } = this.$route.params;
        const { uuid: userId } = this.user;
        const data = { id, userId };

        await this.$di.api.Account.accountRemoveUser(data);
        this.$di.notify.snackSuccess('Администратор удален');

        this.user = { ...DEFAULT_USER };
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
